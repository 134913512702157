
// @import 'node_modules/bootstrap/scss/variables'
// @import 'node_modules/bootstrap/scss/mixins'
// // buttons
// @import 'node_modules/bootstrap/scss/buttons'
	
*
	background-color: transparent !important
	background-image: none !important
	color: black !important
button
	display: none

// yes we re printing but we don t know if we are in small (carousel) or desktop (masonry) layout

header
	.container
		min-height: auto !important


// _________________________________________________________________________________________________________
// masonry hypothesis : 
.projects
	// @extend .container
	.grid
		// @extend .row
		display: flex
		flex-flow: wrap
		// 90% tries to emulate the .container with little margins
		width: 90% !important
		height: auto !important
		.grid-item
			// @extend .col
			float: none !important
			position: static !important
			width: 27% !important
			height: auto !important
			// float: left !important
			a
				text-decoration: none
				text-align: left !important
				border-radius: 0 !important
				border: none !important
				cursor: default
				pointer-events: none 
				&:after
					content: attr(href)
					display: block
				// .title
				// .description
				// .thumbnail

// _________________________________________________________________________________________________________
// carousel hypothesis : 
#carouselExampleIndicators
	.carousel-indicators
		display: none !important
	.carousel-inner
		display: flex !important
		flex-flow: wrap
		.carousel-item
			position: static !important
			display: block !important
			width: 27% !important
			float: none !important
			margin-right: auto !important
			.carousel-image-container
				display: none !important
			.carousel-caption
				position: static !important
				display: block !important
				text-align: left !important
				// h4

				// p

				a
					text-align: left !important
					padding: 0 !important
					margin: 0 !important
					&:after
						content: attr(href)
						display: block
						text-decoration: underline
					&:hover
						border: none !important

// _________________________________________________________________________________________________________
app-older-projects
	clear: both !important
	float: none !important

